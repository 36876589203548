import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { ForbiddenErrorComponent } from './shared/components/forbidden-error/forbidden-error.component';
import { NotFoundErrorComponent } from './shared/components/not-found-error/not-found-error.component';


const routes: Routes = [ 
  { 
    path: 'login', component: LoginComponent
  },
  { 
    path: 'forbidden', component: ForbiddenErrorComponent
  },
  { 
    path: 'notFound', component: NotFoundErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
