import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { Cover } from '../../classes/cover';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class CoversApiService {

  constructor(private http: HttpService) { }

  getCoversOpen() {
    let params = {
      status: 'created'
    }
    return this.http.get(API_ENDPOINT + '/covers', params);
  }

  getCoversInCheck() {
    let params = {
      status: 'in_check'
    }
    return this.http.get(API_ENDPOINT + '/covers', params);
  }

  getCoversForOrderWithID(order_id: number) {
    let params = {
      order_id: order_id
    }
    return this.http.get(API_ENDPOINT + '/covers', params);
  }

  getCoverWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/covers/' + id);
  }

  createCover(cover: Cover) {
      let upload_cover = this.convertCoverToFormdata(cover);
      return this.http.post(API_ENDPOINT + '/covers', upload_cover);
  }

  updateCoverWithID(id: number, cover: Cover) {
      let upload_cover = this.convertCoverToFormdata(cover);
      return this.http.put(API_ENDPOINT + '/covers/' + id, upload_cover);
  }

  deleteCoverWithID(id: number) {
    return this.http.delete(API_ENDPOINT + '/covers/' + id);
  }

  resetCoverWithID(id: number) {
    return this.http.delete(API_ENDPOINT + '/reset_cover/' + id);
  }

  deleteMultipleCovers(ids: number[]) {
    let params = {
      'ids[]': ids
    }
      return this.http.deleteMultiple(API_ENDPOINT + '/covers', params );
  }

  addCoversToOrder(order_id: number, ids: number[]) {
    let params = {
      'ids[]': ids
    }
    return this.http.putMultiple(API_ENDPOINT + '/covers', { order_id: order_id }, params);
  }

  deleteImage(id: number){
    return this.http.delete(API_ENDPOINT + '/image/' + id);
  }

  addFile(file: File, id: number, type: string){
      var pdfdata: FormData = new FormData();
      pdfdata.append("file", file)
      pdfdata.append("id", id.toString())
      pdfdata.append("type", type)
      return this.http.post(API_ENDPOINT + '/file/', pdfdata);
  }


  deleteFile(id: number){
      return this.http.delete(API_ENDPOINT + '/file/'+ id);
  }


  downloadCoversList(type: string) {
    return this.http.getDownload(API_ENDPOINT + '/customer/covermail' + '?format=' + type, type);
  }

  private convertCoverToFormdata(cover: Cover) {
    var formdata: FormData = new FormData();
    let unpermittedKeys = ['id', 'product', 'image'];
    let boolean_keys = ['cover_by_customer', 'desired_dusttrap'];

    for (let key in cover) {
      if (cover[key]) {
        if (unpermittedKeys.includes(key)) {
          delete cover[key];
        }
        else if (key == 'images_before') {
          for (let picture of cover['images_before']) {
            if (picture.file) {
              formdata.append('images_before[]', picture.file);
            }
          }
        }
        else if (key == 'images_after') {
          for (let picture of cover['images_after']) {
            if (picture.file) {
              formdata.append('images_after[]', picture.file);
            }
          }
        }
        else {
          formdata.append(key, cover[key].toString());
        }
      }
      else if (boolean_keys.includes(key)) {
        var value;
        if(cover[key]){
          value = 1;
        }
        else{
          value = 0;
        }
        formdata.append(key, value.toString());
      }
    }
    return formdata;
  }

}
