import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CoversUniteComponent } from '../covers-unite/covers-unite.component';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import { Cover } from '../../../shared/classes/cover';
import { PRIORITIES } from '../../../shared/services/const';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CoversCreateComponent } from '../covers-create/covers-create.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-covers-open',
  templateUrl: './covers-open.component.html',
  styleUrls: ['./covers-open.component.scss']
})
export class CoversOpenComponent implements OnInit {
  private editMode: boolean = false;
  private coversDeleteDialogRef: MatDialogRef<DeleteGuardComponent>;
  private coversUniteDialogRef: MatDialogRef<CoversUniteComponent>;
  private coverCreateDialogRef: MatDialogRef<CoversCreateComponent>;


  public columnsToDisplay = ["priority", "location", "type", "work_method", "product", "action", "select"];


  public covers: Cover[] = [];
  private bookmarkedOrders = [];
  private priorities = PRIORITIES;

  public selectedCovers = [];

  constructor(private dialog: MatDialog, private coversService: CoversApiService, private ordersService: OrdersApiService, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadCovers();
  }

  reloadCovers() {
    this.coversService.getCoversOpen().subscribe(
      covers => {
        this.covers = covers;
      },
      error => {
        console.error(error);
      }
    )
  }

  updateCheckedCovers(id: string, event) {
    let index = this.selectedCovers.indexOf(id);

    if (index > -1) {
      this.selectedCovers.splice(index, 1);
    }
    else {
      this.selectedCovers.push(id);
    }
  }

  addCover() {
      var cover = new Cover();
      if (this.authService.isCustomer()) {
        cover.status = 'created';
        cover['company_id'] = this.authService.user.company_id;
      }
  
      this.coverCreateDialogRef = this.dialog.open(CoversCreateComponent, {
        data: cover,
        disableClose: true,
        panelClass: 'dialogMobileFullWidth'
      });
  
      this.coverCreateDialogRef.afterClosed().subscribe(result => {
  
        if (result && result != undefined) {
          this.coversService.createCover(result).subscribe(
            cover => {
              this.reloadCovers();
            },
            error => {
              console.error(error);
            }
          )
        }
      });  
  }

  uniteCovers() {
    // dialog to "unite" covers
    // new order or add to "vorgemerkte aufträge"
    this.coversUniteDialogRef = this.dialog.open(CoversUniteComponent, {
      data: {
        orders: this.bookmarkedOrders,
        panelClass: 'dialogMobileFullWidth'
      }
    });

    this.coversUniteDialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.functionName == 'newOrder') {
          var order = new Assignment();
          order.status = 'created';
          order.company_id = this.authService.user.company_id;

          this.ordersService.createOrder(order).subscribe(
            order => {
              this.coversService.addCoversToOrder(order.id, this.selectedCovers).subscribe(
                covers => {
                  this.selectedCovers = [];
                  this.reloadCovers();
                },
                error => {
                  console.error(error);
                }
              )
            },
            error => {
              console.error(error);
            }
          )
        }
        else if (result.functionName == 'bookmarkedOrder' && result.bookmarkedOrderId != '') {
          this.coversService.addCoversToOrder(result.bookmarkedOrderId, this.selectedCovers).subscribe(
            covers => {
              this.selectedCovers = [];
              this.reloadCovers();
            },
            error => {
              console.error(error);
            }
          )
        }
      }
    });
  }


  downloadFile(type: string) {
    this.coversService.downloadCoversList(type).subscribe(
      download => {
        var filename = "";
        if (type == 'pdf') {
          filename = 'liste_abdeckungen.pdf';
        }
        else if (type == 'xlsx') {
          filename = 'liste_abdeckungen.xlsx';
        }
        FileSaver.saveAs(download, filename);
                
      },
      error => {
        console.error(error);
      }
    )
  }

}
