// import { Team } from "./team";
import { Customer } from "./customer";

export class User {
    public id: number;
    public name: string;
    public email: string;
    public type: string;
    public company: Customer;
    public phone: string;
    public imported: string;

    static copyObject(orginial: User): User {
        var copiedUser: User = new User();

        for(let property in orginial) {
            copiedUser[property] = orginial[property];
        }

        return copiedUser;
    }
}
