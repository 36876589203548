import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { AssignmentsComponent } from '../assignments/assignments/assignments.component';
import { LoginComponent } from '../../components/login/login.component';
import { CoversComponent } from '../covers/covers/covers.component';
import { AssignmentsProgressComponent } from '../assignments/assignments-progress/assignments-progress.component';
import { AssignmentsCompletedComponent } from '../assignments/assignments-completed/assignments-completed.component';
import { AssignmentsPrecastComponent } from '../assignments/assignments-precast/assignments-precast.component';
import { AssignmentDetailsComponent } from '../assignments/assignment-details/assignment-details.component';
import { CoversOpenComponent } from '../covers/covers-open/covers-open.component';
import { CoverDetailsComponent } from '../covers/cover-details/cover-details.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { PrivacyComponent } from '../privacy/privacy/privacy.component';
import { CoversCreateComponent } from '../covers/covers-create/covers-create.component';
import { SaveGuardService } from '../../shared/services/save-guard.service';
import { CustomerAuthGuardService } from '../../shared/services/customer-auth-guard.service';
import { ImpressComponent } from '../privacy/impress/impress.component';
import { HelpComponent } from '../help/help/help.component';

const routes: Routes = [
  { path: '', redirectTo: '/auftraege/inArbeit', pathMatch: 'full' },
  { path: 'auftraege', redirectTo: '/auftraege/inArbeit', pathMatch: 'full' },
  { path: 'abdeckungen', redirectTo: '/abdeckungen/offen', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'auftraege', 
        component: AssignmentsComponent, 
        children: [
          {
            path: 'inArbeit', 
            component: AssignmentsProgressComponent
          },
          {
            path: 'abgeschlossen', 
            component: AssignmentsCompletedComponent
          },
          {
            path: 'vorgemerkt', 
            component: AssignmentsPrecastComponent,
            canActivate: [CustomerAuthGuardService],
          },
          {
            path: ':id', 
            component: AssignmentDetailsComponent,
            canDeactivate: [SaveGuardService] 
          },
        ]
      },
      {
        path: 'abdeckungen', 
        component: CoversComponent, 
        children: [
          {
            path: 'offen', 
            component: CoversOpenComponent, 
            canActivate: [CustomerAuthGuardService],
          },
          {
            path: ':id', 
            component: CoverDetailsComponent,
            canDeactivate: [SaveGuardService] 
          }
        ]
      },
      {
        path: 'profil', 
        component: ProfileComponent,
        canDeactivate: [SaveGuardService]  
      },
      {
        path: 'datenschutz', 
        component: PrivacyComponent       
      },
      {
        path: 'impressum', 
        component: ImpressComponent       
      },
      {
        path: 'hilfe', 
        component: HelpComponent       
      },
      { path: '**', redirectTo: '/notFound' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
