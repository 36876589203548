import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';

@Injectable({
  providedIn: 'root'
})
export class ProductsApiService {

  constructor(private http: HttpService) { }

  getProducts(type: String) {
    let typeUrl = this.getTypeUrl(type);

    return this.http.get(API_ENDPOINT + '/' + typeUrl + '/');
  }

  getTypeUrl(type: String) {
    var typeUrl = "";
    switch (type) {
      case 'Manholecover':
        typeUrl = 'manholecovers';
        break;
      case 'Streetcap':
        typeUrl = 'streetcaps';
        break;
      case 'Hydrantcap':
        typeUrl = 'hydrantcaps';
        break;
    }
    return typeUrl;
  }
}
