import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssignmentsRoutingModule } from './assignments-routing.module';
import { AssignmentsComponent } from './assignments/assignments.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
// import { AssignmentTemplateComponent } from './assignment-template/assignment-template.component';
import { AssignmentDetailsComponent } from './assignment-details/assignment-details.component';
import { AssignmentsProgressComponent } from './assignments-progress/assignments-progress.component';
import { AssignmentsPrecastComponent } from './assignments-precast/assignments-precast.component';
import { AssignmentsCompletedComponent } from './assignments-completed/assignments-completed.component';
import { AssignmentAddCoverComponent } from './assignment-add-cover/assignment-add-cover.component';
import { OrdersApiService } from '../../shared/services/APIServices/orders-api.service';

@NgModule({
  imports: [
    CommonModule,
    AssignmentsRoutingModule,
    SharedModule,
  ],
  declarations: [
    AssignmentsComponent,
    AssignmentDetailsComponent,
    AssignmentsProgressComponent,
    AssignmentsPrecastComponent,
    AssignmentsCompletedComponent,
    AssignmentAddCoverComponent,
  ],
  providers: [
    OrdersApiService
  ],
  entryComponents: [
    AssignmentAddCoverComponent,
  ]
})
export class AssignmentsModule { }
