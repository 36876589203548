import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { } from '@types/googlemaps';

declare var google: any;

@Component({
  selector: 'app-covers-map',
  templateUrl: './covers-map.component.html',
  styleUrls: ['./covers-map.component.scss']
})
export class CoversMapComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any) { }

  // data with gps coords

  ngOnInit() {
    var mapProp = {
      center: new google.maps.LatLng(this.inputData.lat, this.inputData.lng),
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map(document.getElementById("coverMap"), mapProp);
    var marker = new google.maps.Marker({
      position: {lat: this.inputData.lat, lng: this.inputData.lng},
      map: map,
      title: 'Abdeckung'
    });
  
  }



}
