import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Picture } from '../../../shared/classes/picture';
import { API_ENDPOINT } from '../../../shared/services/const';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-cover-image-details',
  templateUrl: './cover-image-details.component.html',
  styleUrls: ['./cover-image-details.component.scss']
})
export class CoverImageDetailsComponent implements OnInit {
  public currentPosition: number = 0;
  public images : Picture[];

  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any, private dialogRef:MatDialogRef<CoverImageDetailsComponent>, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    if (this.inputData) {
      this.images = this.inputData.images;
      this.currentPosition = this.inputData.index
    }
  }

  onNext() {
    if(this.currentPosition < this.images.length) {
      this.currentPosition++;
    }
  }

  onPrevious() {
    if(this.currentPosition > 0) {
      this.currentPosition--;
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}
