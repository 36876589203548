import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { User } from '../../classes/user';
import { Observable } from 'rxjs';

@Injectable()
export class UserApiService {
  private storageName = 'users';

  constructor(private http: HttpService) { }

  getUserWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/users/' + id);
  }

  updateUserWithID(id: number, user: User) {
      return this.http.put(API_ENDPOINT + '/users/' + id, user);
  }
}
