import { Binary } from "@angular/compiler";
import { SafeResourceUrl } from "@angular/platform-browser";

export class Picture {

    public id: number;
    public name: string;
    public url: string;
    public url_small: string;
    public image_type: string;
    public content_type: string;
    public file: File
}
