import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssignmentsComponent } from '../assignments/assignments.component';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaveGuardComponent } from '../../../shared/components/save-guard/save-guard.component';
import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { CoversCreateComponent } from '../../covers/covers-create/covers-create.component';
import { AssignmentAddCoverComponent } from '../assignment-add-cover/assignment-add-cover.component';
import { Overlay } from '@angular/cdk/overlay';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { COVER_STATUS, ORDER_STATUS, WORK_METHOD, API_ENDPOINT } from '../../../shared/services/const';
import { Cover } from '../../../shared/classes/cover';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.scss']
})
export class AssignmentDetailsComponent implements OnInit {
  // activated route params.id
  // http get assignment with id
  // customer: access just for "own" assignments
  // admin: no access if status: precast

  public order: Assignment;
  public order_id: number;
  public cover_status = COVER_STATUS;
  public work_method = WORK_METHOD;
  public order_status = ORDER_STATUS;
  public baseurl = API_ENDPOINT;
  public visible = false;

  private coverCreateDialogRef: MatDialogRef<CoversCreateComponent>;
  private coverDeleteDialogRef: MatDialogRef<DeleteGuardComponent>;
  private assignmentAddCoverDialogRef: MatDialogRef<AssignmentAddCoverComponent>;

  public columnsToDisplay = ["status", "location", "type", "work_method", "product", "action"];

  constructor(public authService: AuthenticationService, private dialog: MatDialog, private overlay: Overlay, private ordersService: OrdersApiService, private route: ActivatedRoute, private router: Router, private coversService: CoversApiService) {
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.route.params.subscribe(
      params => {
        this.order_id = +params['id']; // + converts the string to number
        this.reloadOrder();
      });
  }

  reloadOrder() {
    this.ordersService.getOrderWithID(this.order_id).subscribe(
      order => {
        this.order = order;
        this.coversService.getCoversForOrderWithID(order.id).subscribe(
          results => {
            this.order.covers = results;
          },
          error => {
            console.error(error);
          }
        )
      },
      error => {
        console.log(error);
      }
    )
  }


  deleteOrder() {
    if(this.order.status == "created") {
      this.coverDeleteDialogRef = this.dialog.open(DeleteGuardComponent, {
        data: {
          bodyText: 'Möchten Sie den Auftrag wirklich löschen?',
          panelClass: 'dialogMobileFullWidth'
        }}
      );
      return this.coverDeleteDialogRef.afterClosed().subscribe(result => {
          this.ordersService.deleteOrderWithID(this.order.id).subscribe(
            deleted => {
              this.router.navigate(['/auftraege/vorgemerkt']);
            },
            error => {
              console.error(error);
            }
          )
        }
      )
    }
  }

  changeStatusTo(status: string) {

    let current_time = new Date().toUTCString()
    if (status == 'in_offer') {
       this.order.status = "in_offer";
       this.order.order_date = current_time;
     }
     if (status == 'passed') {
       this.order.status = "passed";
       this.order.passing_date = current_time;
     }
    this.ordersService.updateOrderWithID(this.order.id, this.order).subscribe(
      order => {
        this.order = order;
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }

  addCover() {
    var cover = new Cover();
    cover.order_id = this.order.id;
    cover.status = 'in_order';

      cover['company_id'] = this.authService.user.company_id;
      this.coversService.getCoversOpen().subscribe(
        covers => {
          // select new Cover or open cover from list
          // if new, hide list elements and "transform" to createCoverComp
          this.assignmentAddCoverDialogRef = this.dialog.open(AssignmentAddCoverComponent,
            {
              minWidth: '500px',
              data: {
                covers: covers,
                cover: cover
              },
              panelClass: 'dialogMobileFullWidth'
            });

          this.assignmentAddCoverDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
              if (result.functionName == 'newCover') {
                this.createNewCover(result.newCover);
              }
              else if (result.functionName == 'selectCovers' && result.selectedCoverIds.length > 0) {
                this.updateMultipleCovers(result.selectedCoverIds);
              }
            }
          });
        },
        error => {
          console.error(error);
        }
      )
  }

  updateMultipleCovers(ids: number[]) {
    this.coversService.addCoversToOrder(this.order.id, ids).subscribe(
      covers => {
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }

  createNewCover(cover: Cover) {
    this.coversService.createCover(cover).subscribe(
      cover => {
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }

  
  downloadFile(type: string) {
    this.ordersService.downloadCoversList(this.order.id, type).subscribe(
      download => {
        var filename = "";
        let order_id = this.order.id;

        if (type == 'pdf') {
          filename = 'liste_auftrag_' + order_id + '.pdf';
        }
        else if (type == 'xlsx') {
          filename = 'liste_auftrag_' + order_id + '.xlsx';
        }
        FileSaver.saveAs(download, filename);
      },
      error => {
        console.error(error);
      }
    )
  }
}
