import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main/main.component';
import { MainNavigationComponent } from '../../components/main-navigation/main-navigation.component';
import { AssignmentsModule } from '../assignments/assignments.module';
import { CoversModule } from '../covers/covers.module';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { ProfileModule } from '../profile/profile.module';
import { PrivacyModule } from '../privacy/privacy.module';
import { SaveGuardService } from '../../shared/services/save-guard.service';
import { CustomerAuthGuardService } from '../../shared/services/customer-auth-guard.service';
import { MainNavigationService } from '../../shared/services/main-navigation.service';
import { SideMainNavigationComponent } from '../../components/side-main-navigation/side-main-navigation.component';
import { CoversApiService } from '../../shared/services/APIServices/covers-api.service';
import { FooterComponent } from './footer/footer.component';
import { HelpModule } from '../help/help.module';


@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    AssignmentsModule,
    CoversModule,
    SharedModule,
    ProfileModule,
    PrivacyModule,
    HelpModule,
  ],
  declarations: [
    MainComponent,
    MainNavigationComponent,
    FooterComponent,
    SideMainNavigationComponent
  ],
  providers: [
    AuthenticationService,
    SaveGuardService,
    CustomerAuthGuardService,
    MainNavigationService, 
    CoversApiService
  ],
})
export class MainModule { }
