import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoversRoutingModule } from './covers-routing.module';
import { CoversComponent } from './covers/covers.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { CoverDetailsComponent } from './cover-details/cover-details.component';
import { CoversOpenComponent } from './covers-open/covers-open.component';
import { CoversCreateComponent } from './covers-create/covers-create.component';
import { CoversUniteComponent } from './covers-unite/covers-unite.component';
import { CoversMapComponent } from './covers-map/covers-map.component';
import { CoverImageDetailsComponent } from './cover-image-details/cover-image-details.component';

@NgModule({
  imports: [
    CommonModule,
    CoversRoutingModule,
    SharedModule,
  ],
  declarations: [
    CoversComponent,
    CoverDetailsComponent,
    CoversOpenComponent,
    CoversCreateComponent,
    CoversUniteComponent,
    CoversMapComponent,
    CoverImageDetailsComponent,
  ],
  providers: [
  ],
  entryComponents: [
    CoversCreateComponent,
    CoversUniteComponent,
    CoversMapComponent,
    CoverImageDetailsComponent
  ]

})
export class CoversModule { }
