import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT, SYNC_STATUS } from '../const';
import { Assignment } from '../../classes/assignment';

@Injectable({
  providedIn: 'root'
})
export class OrdersApiService {

  constructor(private http: HttpService) { }

  getOrderWithStatus(status?: string) {
      return this.http.get(API_ENDPOINT + '/customer/' + status);
  }

  getOrderWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/orders/' + id);
  }

  updateOrderWithID(id: number, order: Assignment) {
      return this.http.put(API_ENDPOINT + '/orders/' + id, order);
  }

  deleteOrderWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/orders/' + id);
  }

  createOrder(order: Assignment) {
      return this.http.post(API_ENDPOINT + '/orders', order);
  }

  downloadCoversList(order_id: number, type: string) {
    return this.http.getDownload(API_ENDPOINT + '/customer/ordermail/' + order_id + '?format=' + type, type);
  }

}
