import { Component, OnInit, OnDestroy } from '@angular/core';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrderCreateComponent } from '../../../shared/components/order-create/order-create.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-assignments-precast',
  templateUrl: './assignments-precast.component.html',
  styleUrls: ['./assignments-precast.component.scss']
})

// CUSTOMER ONLY VIEW!!
export class AssignmentsPrecastComponent implements OnInit {
  public assignments: Assignment[] = [];
  public columnsToDisplay = ["number_covers", "created_at", "offer_requested", "action"];
  private orderCreateDialogRef: MatDialogRef<OrderCreateComponent>;

  constructor(private ordersService: OrdersApiService, private dialog: MatDialog,  private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  reloadOrders() {
    this.ordersService.getOrderWithStatus('planing').subscribe(
      orders => {
        this.assignments = orders;
      },
      error => {
        console.error(error);
      }
    )
  }

  createOrder() {
    this.orderCreateDialogRef = this.dialog.open(OrderCreateComponent,
      {
        panelClass: 'dialogMobileFullWidth'
      }
    );
    this.orderCreateDialogRef.afterClosed().subscribe(result => {
      if (result == 'create' && result != undefined) {

        var order = new Assignment();
        order.status = 'created';
        order.company_id = this.authService.user.company_id;

        this.ordersService.createOrder(order).subscribe(
          order => {
            this.reloadOrders();
          },
          error => {
            console.error(error);
          }
        )
      }
    });
  }


  passOrderWithId(id: Number) {
      this.changeStatus("passed", id);
  }

  getOffer(id: Number) {
    this.changeStatus("in_offer", id);

  }

  getOrderWithId(id: Number) : Assignment {
    for (let order of this.assignments) {
      if (order.id == id) {
        return order;
      }
    }
    
  }

  changeStatus(status: string, id: Number) {
    let order = this.getOrderWithId(id);

    var today = new Date().toISOString().slice(0, 10);
  
    if(status == "passed") {
      order.status = "passed";
      order.passing_date = today;
    }
    else if(status == "in_offer") {
      order.status = "in_offer";
      order.order_date = today;
    }


    this.ordersService.updateOrderWithID(order.id, order).subscribe(
      resultOrder => {
        this.reloadOrders();
      },
      error => {
        console.error(error);
      }
    )
  }
  
}
