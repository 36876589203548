import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainNavigationService } from '../../../shared/services/main-navigation.service';
import { Cover } from '../../../shared/classes/cover';
import { Inject } from '@angular/core';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { Product } from '../../../shared/classes/product';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Picture } from '../../../shared/classes/picture';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';
import { CoverProductsHelper } from '../../../shared/helper/cover-products-helper';

@Component({
  selector: 'app-covers-create',
  templateUrl: './covers-create.component.html',
  styleUrls: ['./covers-create.component.scss']
})
export class CoversCreateComponent implements OnInit {
  public productSelected = {
    manufacturer_id: null,
    product_id: null
  };

  public manufacturers: Manufacturer[] = [];
  public products: Product[];
  public productsToSelect: Product[] = [];
  public other_manufacturer_id = 1;
  public other_product_id = 1;

  constructor(@Inject(MAT_DIALOG_DATA) public cover: Cover, private dialogRef: MatDialogRef<CoversCreateComponent>, public productsService: ProductsApiService, private authService: AuthenticationService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.cover['product_id'] = null;
  }

  onCancel() {
    this.dialogRef.close();
  }

  addImage(files: FileList) {
    // each file gets own filereader, because onload is async
    Array.from(files).forEach(file => {
      if (this.cover.images_before == undefined) {
        this.cover['images_before'] = [];
      }
      var picture = new Picture();
      picture.content_type = file.type;
      picture.file = file;
      picture.name = file.name;
      picture['saveUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
      this.cover['images_before'].push(picture);
    });
  }


  coverTypeChange(event) {
    let type = event.value;
    this.cover.product = new Product();
    this.productSelected.manufacturer_id = null;
    this.productSelected.product_id = null;

    if (type != 'Manholecover') {
      this.cover.work_method = null;
    }
    this.populateMaterialList();
  }

  changeWorkMethod(event) {
    this.productSelected.manufacturer_id = null;
    this.productSelected.product_id = null;
    this.populateManufacturers();
  }

  populateMaterialList() {
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.populateManufacturers();
      },
      error => {
        console.error(error);
      }
    )
  }

  populateSelectableProducts(event) {
    let manufacturer_id = event.value;

    this.productsToSelect = [];
    let coverProductsHelper = new CoverProductsHelper();
    this.productsToSelect =  coverProductsHelper.fillSelectableProducts(this.products, manufacturer_id, this.cover.work_method);
  }

  populateManufacturers() {
    this.manufacturers = [];
    let coverProductsHelper = new CoverProductsHelper();
    if (this.cover.work_method == 'smart_repair'  || this.cover.work_method == 'asphalt_only' ){
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products);
    }
    else {
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products, this.cover.work_method);
    }
  }

  getCoordinates() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.cover.gps_lat = position.coords.latitude;
          this.cover.gps_lng = position.coords.longitude;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

}
