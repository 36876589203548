import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoversCreateComponent } from '../covers-create/covers-create.component';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-covers-unite',
  templateUrl: './covers-unite.component.html',
  styleUrls: ['./covers-unite.component.scss']
})
export class CoversUniteComponent implements OnInit {
  public resultData = {
    functionName: '',
    bookmarkedOrderId: ''
  }
  public selectedOrder;
  public orders = [];

  constructor(private dialogRef:MatDialogRef<CoversCreateComponent>, private ordersService: OrdersApiService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.ordersService.getOrderWithStatus('planing').subscribe(
      orders => {
        this.orders = orders;
      },
      error => {
        console.error(error);
      }
    )
  }

  onCancel() {
    this.dialogRef.close();
  }

  newOrder() {
    this.resultData.functionName = 'newOrder';
  }

  bookmarkedOrder() {
    if (this.selectedOrder) {
      this.resultData.functionName = 'bookmarkedOrder';
      this.resultData.bookmarkedOrderId = this.selectedOrder;
    }
  }
}
