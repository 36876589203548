import { Injectable } from '@angular/core';

@Injectable()
export class MainNavigationService {
  public opened: boolean = false;


  constructor() { }

  toggleNavigation() {
    this.opened = !this.opened;
  }

}
