import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Subscription } from 'rxjs/Subscription';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { ErrorGuardComponent } from '../../../shared/components/error-guard/error-guard.component';
import { SaveGuardComponent } from '../../../shared/components/save-guard/save-guard.component';
import 'rxjs/add/operator/first';
import { CoversMapComponent } from '../covers-map/covers-map.component';
import { CoverImageDetailsComponent } from '../cover-image-details/cover-image-details.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Cover } from '../../../shared/classes/cover';
import { ActivatedRoute } from '@angular/router';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import { COVER_STATUS, PRIORITIES, WORK_METHOD, COVER_TYPES, ASPHALT_TYPES, API_ENDPOINT } from '../../../shared/services/const';
import { Picture } from '../../../shared/classes/picture';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { Product } from '../../../shared/classes/product';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { CoverProductsHelper } from '../../../shared/helper/cover-products-helper';
import { Router} from '@angular/router';


declare var google: any;


@Component({
  selector: 'app-cover-details',
  templateUrl: './cover-details.component.html',
  styleUrls: ['./cover-details.component.scss']
})
export class CoverDetailsComponent implements OnInit, OnDestroy {
  private routeSubscription: any;
  public cover: Cover;
  public cover_status = COVER_STATUS;
  public priorities = PRIORITIES;
  public work_methods = WORK_METHOD;
  public cover_types = COVER_TYPES;
  public asphalt_types = ASPHALT_TYPES;
  private cover_id: number;

  private coverDeleteDialogRef: MatDialogRef<DeleteGuardComponent>;
  private ImageDeleteDialogRef: MatDialogRef<DeleteGuardComponent>;
  private PDFDeleteDialogRef: MatDialogRef<DeleteGuardComponent>;
  private ErrorDialogRef: MatDialogRef<ErrorGuardComponent>;
  private saveBeforeLeaveDialogRef: MatDialogRef<SaveGuardComponent>;
  private mapDialogRef: MatDialogRef<CoversMapComponent>;
  private galleryDialogRef: MatDialogRef<CoverImageDetailsComponent>;

  public manufacturers: Manufacturer[] = [];
  public products: Product[];
  public productsToSelect: Product[] = [];

  public status_options: string[] = [];
  public showWorkSection: boolean = true;

  private restoreCover: Cover;
 
  public other_manufacturer_id = 1;
  public other_product_id = 1;

  public coversEdit: boolean = false;
  

  public columnsToDisplay = ["cover_type", "work_method", "product", "milling_desired", "asphalt", "cover_by_customer", "desired_dusttrap"];
public coverArray = [];

  constructor(public authService: AuthenticationService, private dialog: MatDialog, private sanitizer: DomSanitizer, private route: ActivatedRoute, private coversService: CoversApiService, private productsService: ProductsApiService, private router:Router) {

  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.routeSubscription = this.route.params.subscribe(
      params => {
        this.cover_id = +params['id']; // (+) converts string 'id' to a number
        this.reloadCover();
      });
  }

  reloadCover() {
    this.coversService.getCoverWithID(this.cover_id).subscribe(
      cover => {
        this.cover = cover;
        this.cover.manufacturer_id = cover.product.manufacturer_id;
        this.cover.product_id = cover.product.id;
        this.populateMaterialList();
        this.setImageUrls();
        if (this.cover.images_before && this.cover.images_before.length > 0) {
          this.cover.image = this.cover.images_before[0];
        }
        this.coverArray.push(this.cover);
        this.setShowWorkSection();
      },
      error => {
        console.error(error);
      }
    )
  }

  setImageUrls() {
    if (this.cover.images_before) {
      for (let image of this.cover.images_before) {
        image.url_small = API_ENDPOINT + '/' + image.url_small;
        image.url = API_ENDPOINT + '/' + image.url;
      }
    }
    if (this.cover.images_after) {
      for (let image of this.cover.images_after) {
        image.url_small = API_ENDPOINT + '/' + image.url_small;
        image.url = API_ENDPOINT + '/' + image.url;
      }
    }

  }

  setShowWorkSection() {
    if (this.cover.status != 'done') {
      this.showWorkSection = false;
    }
    else if (this.cover.milling_accomplished == null && this.cover.balancingrings_used == null && this.cover.cement_used == null && this.cover.cement_used == null) {
      this.showWorkSection = false;
    }

  }

  startEditCover() {
    this.restoreCover = Cover.copyObject(this.cover);
    this.coversEdit = true;

    if (this.cover.work_method) {
      this.populateMaterialList();
    }
  }

  deleteCover() {
    this.coverDeleteDialogRef = this.dialog.open(DeleteGuardComponent, {
      data: {
        bodyText: 'Möchten Sie die Abdeckung wirklich löschen? Dieser Vorgang kann NICHT rückgängig gemacht werden.',
        panelClass: 'dialogMobileFullWidth'
      }
    });

    this.coverDeleteDialogRef.afterClosed().subscribe(result => {
      if (result == 'delete' && result != undefined) {
        this.coversService.deleteCoverWithID(this.cover.id).subscribe((data)=>{
          this.endEdit()
          this.router.navigate(['abdeckungen'])
        });

      }
    });
  }


  saveChanges() {
    let tempcover = this.cover
    let tempcover_product = this.cover.product
    tempcover.product = tempcover_product

    this.coversService.updateCoverWithID(this.cover.id, tempcover).subscribe(
      cover => {
        this.cover = cover;
        this.cover.manufacturer_id = cover.product.manufacturer_id;
        this.cover.product_id = cover.product.id;
        this.restoreCover = null;
        this.setImageUrls();
        if (this.cover.images_before && this.cover.images_before.length > 0) {
          this.cover.image = this.cover.images_before[0];
        }
        this.setShowWorkSection();
        this.endEdit();
      },
      error => {
        console.error(error);
      }
    )
  }

  endEdit() {
    this.coversEdit = false;
  }

  cancelCoverEdit() {
    this.cover = Cover.copyObject(this.restoreCover);
    this.restoreCover = null;
    this.endEdit();
  }

  // TODO TEST WITH IE
  getCoordinates() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.cover.gps_lat = position.coords.latitude;
          this.cover.gps_lng = position.coords.longitude;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  canDeactivate() {
      if (this.coversEdit) {
              this.saveBeforeLeaveDialogRef = this.dialog.open(SaveGuardComponent,
            {
              panelClass: 'dialogMobileFullWidth'
            }
          );
          return this.saveBeforeLeaveDialogRef.afterClosed().subscribe(result => {
            if (result == 'confirm' && result != undefined) {
              this.cancelCoverEdit();
              return true;
            }
            else if (result == 'saveChanges' && result !== undefined) {
              this.saveChanges();
              return true;
            }
          });
        }
        else {
        return true;
      }
  }

  showMap() {
    this.mapDialogRef = this.dialog.open(CoversMapComponent,
      {
        minWidth: '500px',
        minHeight: '500px',
        data: {
          lat: Number(this.cover.gps_lat),
          lng: Number(this.cover.gps_lng)
        },
        panelClass: 'dialogMobileFullWidth'
      });
  }

  addImage(type: string, files: FileList) {
    // each file gets own filereader, because onload is async
    Array.from(files).forEach(file => {
      var picture = new Picture();
      picture.content_type = file.type;
      picture.file = file;
      picture.name = file.name;
      picture['saveUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));

      if (type == 'before') {
        if (this.cover.images_before == undefined) {
          this.cover.images_before = [];
        }
        this.cover.images_before.push(picture);
      }
      else if (type == 'after') {
        if (this.cover.images_after == undefined) {
          this.cover.images_after = [];
        }
        this.cover.images_after.push(picture);
      }
      this.saveChanges();
    });
  }

  deleteImage(id: number) {
    // TODO (idea: put ids to delete in array, remove from view, on save => change cover and in callback delete images with id)
      this.ImageDeleteDialogRef = this.dialog.open(DeleteGuardComponent, {
        data: {
          bodyText: 'Möchten Sie das ausgewählte Bild wirklich löschen?',
          panelClass: 'dialogMobileFullWidth'
        }
      });

      this.ImageDeleteDialogRef.afterClosed().subscribe(result => {
        if (result == 'delete' && result != undefined) {
          this.coversService.deleteImage(id).subscribe(
              deleted => {
                this.saveChanges();
              },
              error => {
                console.error(error);
                this.ErrorDialogRef = this.dialog.open(ErrorGuardComponent, {
                  data: {
                    bodyText: error
                  }
                });
              }
            )


        }
      });
    }

  openGallery(type: string, index: number) {
    var images: Picture[];
    if (type == 'before') {
      images = this.cover.images_before;
    }
    else if (type == 'after') {
      images = this.cover.images_after;
    }
    this.galleryDialogRef = this.dialog.open(CoverImageDetailsComponent,
      {
        data: {
          index: index,
          images: images
        },
        panelClass: 'dialogMobileFullWidth'
      });
  }

  ngOnDestroy() {
    this.cancelCoverEdit();
  }

  populateMaterialList() {
      this.productsService.getProducts(this.cover.cover_type).subscribe(
        products => {
          this.products = products;
          this.fillManufacturer();
          if (this.cover.product) {
            this.changeSelectableCovers({value: this.cover.product.manufacturer_id});
          }
        },
        error => {
          console.error(error);
        }
      )
  }

  coverTypeChange(event) {
    this.resetProduct();
    let type = event.value;
    this.cover.product = new Product();
    if (type != 'Manholecover') {
      this.cover.work_method = null;
    }
 
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.fillManufacturer();
      },
      error => {
        console.error(error);
      }
    )
  }

  fillManufacturer() {
    this.manufacturers = [];
    let coverProductsHelper = new CoverProductsHelper();
    if (this.cover.work_method == 'smart_repair' || this.cover.work_method == 'asphalt_only'){
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products);
    }
    else {
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products, this.cover.work_method);
    }
  }

  resetProduct() {
    this.cover.product = null;
    this.cover.product_id  = null;
    this.cover.manufacturer_id = null;
  }

  changeWorkMethod(event) {
    this.resetProduct();
    this.cover.product = new Product();
    this.fillManufacturer();
  }

  changeSelectableCovers(event) {
    this.productsToSelect = [];
    let manufacturer_id = event.value;
    let coverProductsHelper = new CoverProductsHelper();
    this.productsToSelect = coverProductsHelper.fillSelectableProducts(this.products, manufacturer_id, this.cover.work_method);
  }

}
