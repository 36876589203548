import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { MainNavigationService } from '../../shared/services/main-navigation.service';

@Component({
  selector: 'app-side-main-navigation',
  templateUrl: './side-main-navigation.component.html',
  styleUrls: ['./side-main-navigation.component.scss']
})
export class SideMainNavigationComponent implements OnInit {
  private customerLinks = [
    {title: "Aufträge", link: "/auftraege/ausstehend"},
    {title: "Abdeckungen", link: "/abdeckungen/offen"},
    {title: "Profil", link: "/profil"}
  ];
  
  public links;

  constructor(public authService: AuthenticationService, private mainNavService: MainNavigationService) {
    if(this.authService.isAuthenticated) {
        this.links = this.customerLinks;
    }
  }

  ngOnInit() {
    this.authService.jwt_timeout();
  }

  logout() {
    this.authService.logout();
  }


}
