import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-impress',
  templateUrl: './impress.component.html',
  styleUrls: ['./impress.component.scss']
})
export class ImpressComponent implements OnInit {
  
  constructor() { }


  ngOnInit() {
            
  }

}