import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cover } from '../../../shared/classes/cover';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { Product } from '../../../shared/classes/product';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { WORK_METHOD } from '../../../shared/services/const';
import { Picture } from '../../../shared/classes/picture';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';
import { CoverProductsHelper } from '../../../shared/helper/cover-products-helper';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-assignment-add-cover',
  templateUrl: './assignment-add-cover.component.html',
  styleUrls: ['./assignment-add-cover.component.scss']
})
export class AssignmentAddCoverComponent implements OnInit {
  public newCoverMode: boolean = false;
  public cover: Cover;

  public productSelected = {
    manufacturer_id: null,
    product_id: null
  };

  public work_methods = WORK_METHOD;

  public manufacturers: Manufacturer[] = [];
  public products: Product[];
  public productsToSelect: Product[] = [];

  public other_manufacturer_id = 1;
  public other_product_id = 1;


  public resultData = {
    functionName: '',
    selectedCoverIds: [],
  }

  public selectedCovers = [];

  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any, private dialogRef:MatDialogRef<AssignmentAddCoverComponent>, public productsService: ProductsApiService, private sanitizer: DomSanitizer, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
  }

  onCancel() {
    this.dialogRef.close();
  }

  startNewCoverMode() {
    this.newCoverMode = true;
    this.cover = this.inputData.cover;
    this.resultData.functionName = 'newCover';
    this.resultData['newCover'] = this.cover;
    this.cover['product_id'] = null;
  }

  newCover() {
  }

  selectCovers() {
    if (this.selectedCovers) {
      this.resultData.functionName = 'selectCovers';
      this.resultData.selectedCoverIds = this.selectedCovers;
    }
  }

  updateCheckedCovers(id: string, event) {
    let index = this.selectedCovers.indexOf(id);

    if (index > -1) {
      this.selectedCovers.splice(index,1);
    }
    else {
      this.selectedCovers.push(id);
    }
  }

  coverTypeChange(event) {
    let type = event.value;
    this.cover.product = new Product();
    if (type != 'Manholecover') {
      this.cover.work_method = null;
    }
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.fillManufacturer();
      },
      error => {
        console.error(error);
      }
    )
  }

  fillManufacturer() {
    this.productSelected["manufacturer_id"] = null;
    this.productSelected["product_id"] = null;
    this.manufacturers = [];
    let coverProductsHelper = new CoverProductsHelper();
    this.manufacturers = coverProductsHelper.fillManufacturers(this.products, this.cover.work_method);
  }

  addImage(files: FileList) {
    // each file gets own filereader, because onload is async
    Array.from(files).forEach(file => {
      if (this.cover.images_before == undefined) {
        this.cover['images_before'] = [];
      }
      var picture = new Picture();
      picture.content_type = file.type;
      picture.file = file;
      picture.name = file.name;      //picture.url = URL.createObjectURL(file);
      picture['saveUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
      this.cover['images_before'].push(picture);
    });
  }

  changeWorkMethod(event) {
    this.fillManufacturer();
  }

  changeSelectableCovers(event) {
    this.productsToSelect = [];
    let manufacturer_id = event.value;
    let coverProductsHelper = new CoverProductsHelper();
    this.productsToSelect = coverProductsHelper.fillSelectableProducts(this.products, manufacturer_id, this.cover.work_method);
  }

  getCoordinates() {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.cover.gps_lat = position.coords.latitude;
          this.cover.gps_lng = position.coords.longitude;
        },
        error => {
          console.error(error);
        }
      );
    }
  }


}
