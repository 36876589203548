import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImpressComponent } from './impress/impress.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PrivacyComponent, 
    ImpressComponent
  ],
  providers: [
  ]
})
export class PrivacyModule { }
