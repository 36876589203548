import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-guard',
  templateUrl: './error-guard.component.html',
  styleUrls: ['./error-guard.component.scss']
})
export class ErrorGuardComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef:MatDialogRef<ErrorGuardComponent>) { }

  ngOnInit() {
  
  }

  onOK() {
    this.dialogRef.close();
  }

}
