import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { UserApiService } from '../../shared/services/APIServices/user-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ProfileComponent],
  providers: [
    UserApiService
  ]
})
export class ProfileModule { }
