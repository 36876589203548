import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';

import { AuthGuardService } from './shared/services/auth-guard.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoginComponent } from './components/login/login.component';
import { MainRoutingModule } from './modules/main/main-routing.module';
import { MainModule } from './modules/main/main.module';
import { SharedModule } from './shared/modules/shared/shared.module';
import { HttpService } from './shared/services/http.service';
import { HttpModule } from '@angular/http';
import { ForbiddenErrorComponent } from './shared/components/forbidden-error/forbidden-error.component';
import { NotFoundErrorComponent } from './shared/components/not-found-error/not-found-error.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForbiddenErrorComponent,
    NotFoundErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainRoutingModule,
    MainModule,
    FormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AuthGuardService,
    AuthenticationService,
    HttpService,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
