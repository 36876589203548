import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-covers',
  templateUrl: './covers.component.html',
  styleUrls: ['./covers.component.scss']
})
export class CoversComponent implements OnInit {

  constructor(public authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.jwt_timeout();
  }


 
}
