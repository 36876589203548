import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assignments-completed',
  templateUrl: './assignments-completed.component.html',
  styleUrls: ['./assignments-completed.component.scss']
})
export class AssignmentsCompletedComponent implements OnInit, OnDestroy {
  public assignments: Assignment[] = [];

  public columnsToDisplay = ["done_date", "number_covers", "contact", "action"];

  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  clicked(buttonType: string) {
    switch(buttonType) {
      case 'reload':
      this.reloadOrders();
      break;
    }
  }

  reloadOrders() {
      this.ordersService.getOrderWithStatus('done').subscribe(
        orders => {
          this.assignments = orders;
        },
        error => {
          console.error(error);
        }
      )
  }

  ngOnDestroy() {
  }
}
