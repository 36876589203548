import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';

@Component({
  selector: 'app-assignments-progress',
  templateUrl: './assignments-progress.component.html',
  styleUrls: ['./assignments-progress.component.scss']
})
export class AssignmentsProgressComponent implements OnInit, OnDestroy {
  public assignments: Assignment[] = [];
  public columnsToDisplay = ["progress", "created_at", "contact", "action"];


  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  clicked(buttonType: string) {
    switch(buttonType) {
      case 'reload':
      this.reloadOrders();
      break;
    }
  }

  reloadOrders() {
      this.ordersService.getOrderWithStatus('send').subscribe(
        orders => {
          this.assignments = orders;
        },
        error => {
          console.error(error);
        }
      )
  }

  ngOnDestroy() {
  }
}

