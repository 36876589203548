import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { MainNavigationService } from '../../shared/services/main-navigation.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {
  // private customerLinks = [
  //   {title: "Aufträge", link: "/auftraege/ausstehend"},
  //   {title: "Abdeckungen", link: "/abdeckungen/offen"},
  //   {title: "Benutzerprofil", link: "/profil"},
  // ];

  // public links;

  constructor(public authService: AuthenticationService, private mainNavService: MainNavigationService) {
    // if(this.authService.isAuthenticated && this.authService.isCustomer()) {
    //     this.links = this.customerLinks;
    //   }
    }

  ngOnInit() {
    this.authService.jwt_timeout();
  }

  logout() {
    this.authService.logout();
  }

  showMenu() {
    this.mainNavService.toggleNavigation();
  }

}
