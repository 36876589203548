// export let API_ENDPOINT = 'https://kundenportaldemo.inuit.solutions'
// export let API_ENDPOINT = 'https://testapi.inuit.solutions'
export let API_ENDPOINT = 'https://kundenportalapi.kds-technik.de'
// export let API_ENDPOINT = 'http://localhost:3100';



export let PRIORITIES = {
    "urgent": "Dringend",
    "normal": "Normal",
    "not_urgent": "Nicht dringend"
};

export let ORDER_STATUS = {
    "created": "Angelegt",
    "in_offer": "Angebot angefragt",
    "passed": "Freigegeben",
    "in_progress": "In Arbeit",
    "done": "Fertigstellt",
}
export let COVER_STATUS = {
    "created": "Angelegt",
    "not_possible": "Nicht möglich",
    "in_order": "In Auftrag",
    "in_check": "In Prüfung",
    "done": "Fertigstellt"
};

export let WORK_METHOD = {
    "einwalzbar": "Einwalzbar",
    "standard": "Standard",
    "budaplan": "Konisch",
    "smart_repair": "Alte Abdeckung neu setzen",
    "asphalt_only": "Nur Asphaltdecke"
};

export let COVER_TYPES = {
    "Manholecover": "Kanaldeckel",
    "Streetcap": "Schieberkappe",
    "Hydrantcap": "Hydrantenkappe"
};

export let ASPHALT_TYPES = {
    "hot": "Heißasphalt",
    "cold": "Kaltasphalt",
    "cast_hot": "Heißverguss"
};

export let SYNC_STATUS = {
    "updated": "updated",
    "created": "created"
};

