import { Customer } from "./customer";
import { Team } from "./team";
import { Cover } from "./cover";
import { PDF } from "./pdf"

export class Assignment {
    public id: number;
    public status: string;
    public order_date: string;
    public passing_date: string;
    public done_date: string;
    public company: Customer;
    public company_id: number;
    public team: Team;
    public team_id: number;
    public contact_construction: string;
    public main_contact_name: string;
    public main_contact_phone: string;
    public kds_contact_name: string;
    public kds_contact_phone: string;
    public covers_done: number;
    public covers_in_order: number;
    public covers: Cover[];
    public created_at: string;
    public vra: PDF;
    public enquiry: PDF;
}
