import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from "@angular/http";
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class HttpService {
  private hasDefaultHeader: boolean = false;
  private header: HttpHeaders;

  constructor(private http: HttpClient) { }

  setDefaultHeader(hasDefaultHeader: boolean, token?: string) {
    this.hasDefaultHeader = hasDefaultHeader;

    if (hasDefaultHeader) {
      this.header = new HttpHeaders({
        //  'Content-Type':  'application/json',
        'Authorization': token
      })

    }
    else {
      this.header = null;
    }
  }

  get(url: string, params?: Object) {
    var options = {
      headers: this.header
    }
    if (params) {
      options['params'] = params;
    }
    return this.http.get<any>(url, options);
  }


  getDownload(url: string, type: string) {
    let token = this.header.get("Authorization");
    let header = new HttpHeaders({
      'Authorization': token,
      'Accept': type,
    })

    
    var options = {
      headers: header,
      responseType: 'blob'
    }
    
    return this.http.get(url, {headers: header, responseType: 'blob'});
  }


  createHttpParams(params: Object): HttpParams {
    var parameter = new HttpParams();
    for (let key in params) {
      parameter.append(key, params[key]);
    }
    return parameter;
  }

  post(url: string, data) {
    return this.http.post<any>(url, data, { headers: this.header });
  }

  put(url: string, data) {
    return this.http.put<any>(url, data, { headers: this.header });
  }

  putMultiple(url: string, data, params: Object) {
    var options = {
      headers: this.header
    }
    options['params'] = params;

    return this.http.put<any>(url, data, options);
  }

  delete(url: string) {
    var options = {
      headers: this.header
    }
    return this.http.delete<any>(url, options);
  }

  deleteMultiple(url: string, params: Object) {
    var options = {
      headers: this.header
    }
    options['params'] = params;
    return this.http.delete<any>(url, options);
  }

}

