import { Component, OnInit } from '@angular/core';
import { MainNavigationService } from '../../../shared/services/main-navigation.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(public mainNavService: MainNavigationService, private authService: AuthenticationService)
  {
    this.authService.jwt_timeout();
  }


  ngOnInit() {
  }
}
