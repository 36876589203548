import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<OrderCreateComponent>) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close();
  }
}
